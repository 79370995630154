import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import useTranslations from "../hooks/useTranslations"
import { LocaleContext } from "../components/layout"
import {
  CreditCardIcon,
  ShieldCheckIcon,
  CloudIcon,
} from '@heroicons/react/outline'

import Accordion from '../components/accordion';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FaqsPage(props) {
    const { locale } = React.useContext(LocaleContext)
    const { faqPage } = useTranslations()
    const [isActivePayment, setIsActivePayment] = useState(false);
    const [isActiveReservation, setIsActiveReservation] = useState(false);
    const [isActiveWeather, setIsActiveWeather] = useState(false);

    return (
        <>
            <Helmet>
                <title>{locale === 'de' ? 'Häufige Fragen' : 'Frequently asked questions'}</title>
            </Helmet>
            <div className="px-4 mx-auto my-8 max-w-7xl md:px-6">
                <h1 className="mb-4 text-xl md:text-3xl md:mb-8">{faqPage.title}</h1>
                <div className="grid">

                    <div className="grid order-2 gap-4 mb-4 text-sm lg:order-1 md:my-12 md:grid-cols-6 lg:grid-cols-8 lg:grid-rows-2 place-items-center">
                
                        <button
                            onClick={() => setIsActiveReservation(!isActiveReservation)}
                            className={classNames(
                            isActiveReservation ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <ShieldCheckIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{faqPage.reservation}</div>
                        </button>
                        <button
                            onClick={() => setIsActivePayment(!isActivePayment)}
                            className={classNames(
                            isActivePayment ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <CreditCardIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{faqPage.payment}</div>
                        </button>
                        
                        <button
                            onClick={() => setIsActiveWeather(!isActiveWeather)}
                            className={classNames(
                            isActiveWeather ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <CloudIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{faqPage.badWeather}</div>
                        </button>
                        <div className="grid self-end gap-1 md:col-span-6 lg:col-span-2 justify-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <div>{faqPage.noAnswers}</div>
                        </div>
                        <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;" className="grid w-full gap-1 p-2 text-xs text-center bg-gray-200 ring-black hover:ring-1 md:p-4 justify-items-center md:col-start-2 lg:col-start-auto md:col-span-2 lg:col-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            {faqPage.write}
                        </a>
                        <a href="tel:+491723250009" className="grid w-full gap-1 p-2 text-xs text-center bg-gray-200 ring-black hover:ring-1 md:p-4 justify-items-center md:col-span-2 lg:col-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                            {faqPage.call}
                        </a>
                    </div>

                    <dl className="grid max-w-4xl mb-4 gap-y-2 md:mb-12 gap-x-8 lg:order-2">
                        {props.data.allGoogleFaqSheet.nodes.map(node => {
                            const title = locale === "de" ? node.titleDe : node.titleEn
                            const text = locale === "de" ? node.textDe : node.textEn
                            return (
                                <Accordion
                                    key={node.id}
                                    title={title}
                                    content={text}
                                    id={node.id}
                                    open={isActivePayment ? true : false}
                                    togglePayment={isActivePayment}
                                    toggleReservation={isActiveReservation}
                                    toggleWeather={isActiveWeather}
                                    group={node.group}
                                />
                            )
                        })}
                    </dl>
                </div>
            </div>
        </>
    )
}

export const query = graphql`
  query FAQ {
    allGoogleFaqSheet {
      nodes {
        id
        titleDe
        textDe
        titleEn
        textEn
        group
      }
    }
  }
`